import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { getData, getFile } from '../../../../utils/fetch';

export const listPurchase = async (search, page) => {
	return await getData('/Purchase/list', {
		pagina: page,
		cantidad: 10,
		texto: search,
	})
		.then((r) => {
			return {
				count: r.cuenta,
				data: r.datos.map(({monto, fecha, origen, estadoPayPal}) => ({
					amount: monto,
					date: format(new Date(fecha), 'dd/MM/yyyy hh:mm aa', {
						locale: es,
					}),
					origin: origen,
					status: estadoPayPal,
				})),
			};
		})
		.catch((e) => ({count: 0, data: []}));
};

export const downloadReport = async () => {
	return await getFile('/Purchase/download', {}).catch((e) => ({url: ''}));
};
