import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AutoRedirect from './components/navigation/AutoRedirect';

import Providers from './components/Providers';
import Firmware from './pages/admin/firmware/Firmware';
import FirmwareDelete from './pages/admin/firmware/FirmwareDelete';
import FirmwareForm from './pages/admin/firmware/FirmwareForm';
import AssignMachineProducts from './pages/admin/machines/AssignMachineProducts';
import MachineDelete from './pages/admin/machines/MachineDelete';
import MachineForm from './pages/admin/machines/MachineForm';
import MachineRefill from './pages/admin/machines/MachineRefill';
import Machines from './pages/admin/machines/Machines';
import Products from './pages/admin/products/Product';
import ProductDelete from './pages/admin/products/ProductDelete';
import ProductForm from './pages/admin/products/ProductForm';
import Reports from './pages/admin/reports/Reports';
import UserDelete from './pages/admin/users/UserDelete';
import UserForm from './pages/admin/users/UserForm';
import Users from './pages/admin/users/Users';
import Home from './pages/Home';
import Login from './pages/login/Login';
import Payment from './pages/payment/Payment';
import Root from './pages/Root';
import Splash from './pages/Splash';
import Policies from './pages/policies/Policies';
import PublicMachines from './pages/machines/PublicMachines';

function App() {
	return (
		<Providers>
			<Routes>
				<Route path='/' element={<Root />}>
					<Route index element={<Splash />} />
					<Route path='login' element={<Login />} />
					<Route path='home' element={<Home />}>
						<Route
							index
							element={<AutoRedirect to='/home/users' />}
						/>
						<Route path='reports' element={<Reports />}></Route>
						<Route path='users' element={<Users />}>
							<Route path='form' element={<UserForm />} />
							<Route path='form/:id' element={<UserForm />} />
							<Route
								path='delete/:id/:desc'
								element={<UserDelete />}
							/>
						</Route>
						<Route path='products' element={<Products />}>
							<Route path='form' element={<ProductForm />} />
							<Route path='form/:id' element={<ProductForm />} />
							<Route
								path='delete/:id/:desc'
								element={<ProductDelete />}
							/>
						</Route>
						<Route path='firmware' element={<Firmware />}>
							<Route path='form' element={<FirmwareForm />} />
							<Route
								path='delete/:id/:desc'
								element={<FirmwareDelete />}
							/>
						</Route>
						<Route path='machines' element={<Machines />}>
							<Route path='form' element={<MachineForm />} />
							<Route path='form/:id' element={<MachineForm />} />
							<Route
								path='delete/:id/:desc'
								element={<MachineDelete />}
							/>
							<Route
								path='refill/:id/:desc'
								element={<MachineRefill />}
							/>
							<Route
								path='assign/:id/:desc'
								element={<AssignMachineProducts />}
							/>
						</Route>
					</Route>
				</Route>
				<Route path='/payment/:id' element={<Payment />} />
				<Route path='/machine/:id' element={<PublicMachines />} />
				<Route path='/policies/' element={<Policies />} />
			</Routes>
		</Providers>
	);
}

export default App;
