import { useState } from 'react';
import { useAlert } from '../../../../components/alert/AlertProvider';
import useMutationForm from '../../../../hooks/useMutationForm';
import useQueryForm from '../../../../hooks/useQueryForm';
import { QUERY_KEY } from '../config/consts';
import { editMachine, getMachine, newMachine } from './requests';

function useUserData() {
	const [id, setId] = useState('');
	const [location, setLocation] = useState('');
	const [phone, setPhone] = useState('');
	const [slots, setSlots] = useState(12);

	return {
		id,
		setId,
		location,
		setLocation,
		phone,
		setPhone,
		slots,
		setSlots,
	};
}

export default function useMachine() {
	// Data hooks
	const data = useUserData();
	const {id, phone, location, setId, setPhone, setLocation, slots, setSlots} =
		data;

	// Custom hooks
	const {fireSuccessInDialog, fireError} = useAlert();
	const {
		id: paramId,
		isEdit,
		isLoading: isLoadingQuery,
	} = useQueryForm([QUERY_KEY], {
		getRequest: getMachine,
		dataSetters: {
			id: setId,
			phone: setPhone,
			location: setLocation,
			slots: setSlots,
		},
	});
	const mutation = useMutationForm({
		addRequest: newMachine,
		editRequest: editMachine,
		idObject: isEdit ? {paramId} : undefined,
		fireSuccess: fireSuccessInDialog,
		key: [QUERY_KEY],
		fireError,
	});

	const handleSubmit = () => mutation.mutate({id, phone, location, slots});

	return {
		...data,
		handleSubmit,
		isLoading: isLoadingQuery || mutation.isLoading,
		isEdit,
	};
}
