import { Box } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAlert } from '../../../components/alert/AlertProvider';
import PageDialog from '../../../components/modal/PageDialog';
import { dp } from '../../../theme/utils';
import AddButton from './components/AddButton';
import ProductItem from './components/ProductItem';
import {
	assignProductToMachine,
	deleteProductToMachine,
	productsByMachine
} from './utils/requests';

export default function AssignMachineProducts() {
	const queryClient = useQueryClient();
	const {desc, id} = useParams();
	const {fireError} = useAlert();
	const {data, isLoading} = useQuery(
		['products', 'assign', id],
		async ({queryKey: [, , machine]}) => {
			return await productsByMachine(machine);
		}
	);
	const mutation = useMutation(async ({product, machine, position}) => {
		return await assignProductToMachine(machine, product, position + 1);
	});
	const mutationDelete = useMutation(async ({machine, position}) => {
		return await deleteProductToMachine(machine, position + 1);
	});

	const handleAdd = (position) => (product) =>
		mutation.mutate(
			{machine: id, product, position},
			{
				onSuccess: () => {
					queryClient.invalidateQueries(['products', 'assign']);
				},
				onError: (e) => {
					fireError({content: e.message});
				},
			}
		);

	const handleDelete = (position) => () =>
		mutationDelete.mutate(
			{machine: id, position},
			{
				onSuccess: () => {
					queryClient.invalidateQueries(['products', 'assign']);
				},
				onError: (e) => {
					fireError({content: e.message});
				},
			}
		);

	return (
		<PageDialog
			title={'Productos en maquina ' + desc}
			titleIcon='inventory'
			loading={isLoading}
			hideDone
			width={'80vw'}
		>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: '1fr 1fr 1fr',
					gap: dp(12),
				}}
			>
				{(data ? data : []).map((_, i) => {
					return data && data[i] ? (
						<ProductItem
							key={'item' + i + '_' + data[i].id}
							id={data[i].id}
							name={data[i].name}
							sold={data[i].sold}
							onUpdate={handleAdd(i)}
							onDelete={handleDelete(i)}
						/>
					) : (
						<AddButton onAdd={handleAdd(i)} key={'non_' + i} />
					);
				})}
			</Box>
		</PageDialog>
	);
}
