import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function useMutationDelete({
	deleteRequest,
	id,
	fireSuccess,
	fireError,
	key,
}) {
	const queryClient = useQueryClient();
	const mutation = useMutation(async (id) => {
		return await deleteRequest(id);
	});

	const mutate = () =>
		mutation.mutate(id, {
			onSuccess: () => {
				fireSuccess();
				queryClient.invalidateQueries([...key]);
			},
			onError: (e) => fireError({content: e.message}),
		});

	return {mutate, isLoading: mutation.isLoading};
}
