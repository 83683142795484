import { deleteData, getData, postData, putData } from '../../../../utils/fetch';
import { CONTROLLER } from '../config/consts';

export const getMachine = async (id) => {
	return await getData(CONTROLLER + 'get/' + id).then(
		({maquina: {nombre, ubicacion, telefono, cavidades}}) => {
			return {
				id: nombre,
				location: ubicacion,
				phone: telefono,
				slots: cavidades,
			};
		}
	);
};

export const newMachine = async ({id, location, phone, slots}) => {
	return await postData(CONTROLLER + 'register', {
		nombre: id,
		ubicacion: location,
		telefono: phone,
		cavidades: slots,
	});
};

export const editMachine = async ({id, location, phone, paramId, slots}) => {
	return await putData(CONTROLLER + 'update/' + paramId, {
		nombre: id,
		ubicacion: location,
		telefono: phone,
		cavidades: slots,
	});
};

export const deleteMachine = async (id) => {
	return await deleteData(CONTROLLER + 'remove/' + id);
};

export const refillMachine = async (id) => {
	return await putData(CONTROLLER + 'refill/' + id);
};

export const listMachines = async (search, page) => {
	return await getData(CONTROLLER + 'list', {
		pagina: page,
		cantidad: 10,
		texto: search,
	})
		.then((r) => {
			return {
				count: r.cuenta,
				data: r.datos.map(
					({
						_id,
						nombre,
						ubicacion,
						telefono,
						estaActivo,
						cavidades,
					}) => ({
						location: ubicacion,
						phone: telefono,
						online: Boolean(estaActivo),
						id: nombre,
						slots: cavidades,
						_id,
					})
				),
			};
		})
		.catch((e) => ({count: 0, data: []}));
};

export const productsByMachine = async (machine) => {
	return await getData(CONTROLLER + 'getProductsByMachine/' + machine).then(
		({productos, comprado}) => {
			const r = productos.map((v, i) =>
				v
					? {
							id: v._id,
							name: v.nombre,
							categories: v.categorias.map((v) => v.nombre),
							sold: comprado[i],
					  }
					: null
			);
			return r;
		}
	);
};

export const assignProductToMachine = async (machine, product, position) => {
	return await putData(CONTROLLER + 'updateProduct/' + machine, {
		producto: product,
		indiceCasillero: position,
	});
};

export const deleteProductToMachine = async (machine, position) => {
	return await deleteData(CONTROLLER + 'removeProduct/' + machine, {
		indiceCasillero: position,
	});
};

export const selectProducts = async () => {
	return await getData('Product/select').then((r) => {
		return r.datos.map(({_id, nombre}) => ({
			value: _id,
			label: nombre,
		}));
	});
};
