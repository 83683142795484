import { Box } from "@mui/material";
import React from "react";
import Spinner from "../components/feedback/spinner";

export default function Splash() {
    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spinner />
        </Box>
    );
}
