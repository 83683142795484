import { Button, Icon } from '@mui/material';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import DeleteIconButton from '../../../components/buttons/DeleteIconButton';
import DownloadIconButton from '../../../components/buttons/DownloadButton';
import SearchField from '../../../components/input/SearchField';
import Table from '../../../components/table/Table';
import Header from '../../../components/typography/Header';
import Container from '../../../components/utils/Container';
import firmware from '../../../config/headers/firmware';
import useQueryList from '../../../hooks/useQueryList';
import addOptions from '../../../utils/addOptions';
import { getBlob } from '../../../utils/fetch';
import { listFirmware } from './utils/requests';

export default function Firmware() {
	const navigate = useNavigate();
	const {search, setSearch, count, page, setPage, data, rowsPerPage} =
		useQueryList(['firmwares'], {
			listData: listFirmware,
		});

	const handleDownload = (file, version) => async () => {
		const r = await getBlob('/Firmware/download/' + file);
		const url = window.URL.createObjectURL(
			new Blob([r.data], {type: 'application/octet-stream'})
		);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', r.headers['nombre-archivo']);
		link.setAttribute('target', '_blank');
		document.body.appendChild(link);
		link.click();
		link.remove();
	};

	return (
		<>
			<Container>
				<Header title='Firmware'>
					<Button
						color='primary'
						endIcon={<Icon>add</Icon>}
						onClick={(e) => navigate('/home/firmware/form')}
					>
						Nuevo firmware
					</Button>
				</Header>
				<SearchField value={search} onChange={setSearch} />
				<Table
					options
					headers={firmware}
					data={addOptions(
						data ? data.data : [],
						({_id, version, file}) => [
							<DownloadIconButton
								key={'download_' + _id}
								onClick={handleDownload(file, version)}
							/>,
							<DeleteIconButton
								key={'delete_' + _id}
								onClick={(e) =>
									navigate(
										'/home/firmware/delete/' +
											_id +
											'/' +
											version
									)
								}
							/>,
						]
					)}
					count={count}
					page={page}
					onPage={setPage}
					rowsPerPage={rowsPerPage}
				/>
			</Container>
			<Outlet />
		</>
	);
}
