import { useState } from 'react';
import { useAlert } from '../../../../components/alert/AlertProvider';
import useMutationForm from '../../../../hooks/useMutationForm';
import useQueryForm from '../../../../hooks/useQueryForm';
import { editUser, getUser, newUser } from './requests';

function useUserData() {
	const [user, setUser] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [email, setEmail] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [name, setName] = useState('');

	return {
		user,
		setUser,
		password,
		setPassword,
		confirmPassword,
		setConfirmPassword,
		showPassword,
		setShowPassword,
		email,
		setEmail,
		name,
		setName,
	};
}

export default function useUser() {
	// Data hooks
	const data = useUserData();
	const {
		setEmail,
		setUser,
		setPassword,
		setName,
		user,
		email,
		password,
		name,
	} = data;

	// Custom hooks
	const {fireSuccessInDialog, fireError} = useAlert();
	const {
		id,
		isEdit,
		isLoading: isLoadingQuery,
	} = useQueryForm(['users'], {
		getRequest: getUser,
		dataSetters: {
			email: setEmail,
			user: setUser,
			password: setPassword,
			name: setName,
		},
	});
	const mutation = useMutationForm({
		addRequest: newUser,
		editRequest: editUser,
		idObject: isEdit ? {id} : undefined,
		fireSuccess: fireSuccessInDialog,
		key: ['users'],
		fireError,
	});

	const handleSubmit = () => mutation.mutate({user, password, email, name});

	return {
		...data,
		handleSubmit,
		isLoading: isLoadingQuery || mutation.isLoading,
		isEdit,
	};
}
