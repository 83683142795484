import { Icon, IconButton, TextField } from '@mui/material';
import React, { useCallback } from 'react';
import { dp } from '../../theme/utils';

export default function OutlinedField({
	label,
	value,
	onChange,
	color,
	icon,
	readOnly,
	onClick,
	onClear,
	onIconClick,
	disableClear,
	...props
}) {
	const handleClear = useCallback(() => onChange(''), [onChange]);

	return (
		<TextField
			label={label}
			value={value}
			onChange={(e) => onChange(e.target.value, e)}
			variant='outlined'
			color={color}
			fullWidth
			sx={{
				'& .MuiOutlinedInput-root': {
					'& fieldset': {
						borderColor: 'outline.main',
					},
					'&: fieldset': {
						borderColor: 'text.secondary',
					},
				},
			}}
			InputProps={{
				readOnly,
				startAdornment: icon ? (
					<Icon sx={{marginRight: dp(16)}}>{icon}</Icon>
				) : undefined,
				endAdornment:
					value !== '' && !disableClear ? (
						<IconButton onClick={onClear ? onClear : handleClear}>
							<Icon>close</Icon>
						</IconButton>
					) : undefined,
				onClick,
			}}
			{...props}
		/>
	);
}
