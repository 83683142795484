import React from 'react';
import { useNavigate } from 'react-router-dom';
import { dp } from '../../theme/utils';
import CancelButton from './buttons/CancelButton';
import OkButton from './buttons/OkButton';
import Dialog from './Dialog';
import DialogActions from './DialogActions';
import DialogContent from './DialogContent';
import DialogHeader from './DialogHeader';

export default function PageDialog({
	title,
	titleIcon,
	children,
	loading,
	onDone,
	doneText,
	doneIcon,
	cancelText,
	cancelIcon,
	onCancel,
	doneDisabled,
	onClose,
	hideDone,
	width,
}) {
	const navigate = useNavigate();

	const handleClose = () => {
		if (!loading) {
			navigate(-1);
		}
		if (onClose) {
			onClose();
		}
	};

	return (
		<Dialog
			open={true}
			onClose={handleClose}
			sx={{width: width || dp(400)}}
			width={width}
		>
			<DialogHeader title={title} icon={titleIcon} />
			<DialogContent>{children}</DialogContent>
			<DialogActions sx={{padding: dp(0)}}>
				<CancelButton
					title={cancelText}
					icon={cancelIcon}
					onClick={onCancel || handleClose}
				/>
				{!hideDone && (
					<OkButton
						title={doneText}
						icon={doneIcon}
						loading={loading}
						disabled={doneDisabled}
						onClick={onDone}
					/>
				)}
			</DialogActions>
		</Dialog>
	);
}
