import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function useMutationForm({
	addRequest,
	editRequest,
	idObject,
	fireSuccess,
	fireError,
	key,
}) {
	const queryClient = useQueryClient();
	const mutation = useMutation(async (data) => {
		if (idObject) {
			return await editRequest({...data, ...idObject});
		}

		return addRequest(data);
	});

	const mutate = (data) => {
		mutation.mutate(data, {
			onSuccess: () => {
				fireSuccess();
				queryClient.invalidateQueries([...key]);
			},
			onError: (e) => fireError({content: e.message}),
		});
	};

	return {mutate, isLoading: mutation.isLoading};
}
