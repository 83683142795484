import {Button, CircularProgress} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {dp} from '../../theme/utils';

export default function PublicMachines() {
	const {id} = useParams();
	const _link = useRef(null);
	const [opened, setOpened] = useState(false);
    /*useEffect(() => {
		const timeout = setTimeout(() => {
            _link.current.click();
			setOpened(true);
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [_link.current]);*/

	return (
		<div
			style={{
				width: '100vw',
				height: '100vh',
				color: '#d22779',
				position: 'absolute',
				top: 0,
				left: 0,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				gap: dp(32),
			}}
		>
			<Button
                color={'primary'}
                variant={'text'}
                size='large'
                style={{transform: 'scale(1.2)'}}
				LinkComponent={'a'}
				href={''}
				ref={_link}
            >
                Descargar para iOS
            </Button>
            <Button
                color={'primary'}
                variant={'text'}
                size='large'
                style={{transform: 'scale(1.2)'}}
				LinkComponent={'a'}
				href={''}
				ref={_link}
            >
                Descargar para Android
            </Button>
			<Button
                color={'primary'}
                variant={'text'}
                size='large'
                style={{transform: 'scale(1.2)'}}
				LinkComponent={'a'}
				href={'vibe://products/' + id}
				ref={_link}
            >
                Ya descargada? Abrir la aplicacion
            </Button>			
		</div>
	);
}
