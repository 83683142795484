import {Button, CircularProgress} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {dp} from '../../theme/utils';

export default function Payment() {
	const {id} = useParams();
	const _link = useRef(null);
	const [opened, setOpened] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
            _link.current.click();
			setOpened(true);
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [_link.current]);

	return (
		<div
			style={{
				width: '100vw',
				height: '100vh',
				color: '#d22779',
				position: 'absolute',
				top: 0,
				left: 0,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				gap: dp(32),
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					gap: dp(4),
				}}
			>
				<span style={{fontSize: dp(32), fontWeight: 'bold'}}>
					Compra completada
				</span>
			</div>
			{!opened && <CircularProgress />}
			<Button
				color={opened ? 'primaryContainer' : 'primary'}
				variant={opened ? 'contained' : 'text'}
				size='large'
				style={{transform: 'scale(1.2)'}}
				LinkComponent={'a'}
				href={'vibe://paymentSuccess/' + id}
				ref={_link}
			>
				{opened
					? 'Toca aquí para volver la aplicación'
					: 'Volviendo a la aplicación'}
			</Button>
		</div>
	);
}
