import React from 'react';
import OutlinedField from '../../../components/input/OutlinedField';
import OutlinedFileField from '../../../components/input/OutlinedFileField';
import PageDialog from '../../../components/modal/PageDialog';
import useFirmware from './utils/useFirmware';

export default function FirmwareForm() {
	const {version, setVersion, file, setFile, handleSubmit, isLoading} =
		useFirmware();

	return (
		<PageDialog
			title={'Nuevo firmware'}
			titleIcon={'note_add'}
			onDone={handleSubmit}
			loading={isLoading}
		>
			<OutlinedField
				label={'Versión'}
				icon='memory'
				value={version}
				onChange={setVersion}
			/>
			<OutlinedFileField
				value={file}
				onChange={setFile}
				label='Archivo'
				placeholder='Selecciona un archivo'
				icon='file_upload'
			/>
		</PageDialog>
	);
}
