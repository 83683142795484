import {Icon, IconButton} from '@mui/material';
import React from 'react';

export default function DownloadIconButton({onClick}) {
	return (
		<IconButton color='tertiary' size='small' onClick={onClick}>
			<Icon fontSize='small'>download</Icon>
		</IconButton>
	);
}
