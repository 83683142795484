import {
	deleteData,
	getData,
	postData,
	postFiles,
	putData
} from '../../../../utils/fetch';
const controller = 'Product/';

export const getProduct = async (id) => {
	return await getData(controller + 'get/' + id).then(
		({producto: {nombre, descripcion, precio, categorias}}) => {
			return {
				name: nombre,
				description: descripcion,
				price: precio,
				images: [],
				categories: categorias.map(({_id, nombre}) => ({
					value: _id,
					label: nombre,
				})),
			};
		}
	);
};

export const newProduct = async ({
	name,
	description,
	price,
	images,
	categories,
}) => {
	const imagenes = [];
	const valsImagenes = Object.values(images);
	for (let i = 0; i < valsImagenes.length; i++) {
		const imagen = valsImagenes[i];
		const r = await postFiles(controller + 'uploadImage', {imagen});
		imagenes.push(r._id);
	}

	return await postData(controller + 'register', {
		nombre: name,
		descripcion: description,
		precio: price,
		categorias: categories,
		imagenes,
	});
};

export const editProduct = async ({
	id,
	name,
	description,
	price,
	images,
	categories,
}) => {
	const imagenes = [];
	const valsImagenes = Object.values(images);
	for (let i = 0; i < valsImagenes.length; i++) {
		const imagen = valsImagenes[i];
		const r = await postFiles(controller + 'uploadImage', {imagen});
		imagenes.push(r._id);
	}

	return await putData(controller + 'update/' + id, {
		nombre: name,
		descripcion: description,
		precio: price,
		categorias: categories,
		imagenes,
	});
};

export const deleteProduct = async (id) => {
	return await deleteData(controller + 'remove/' + id);
};

export const listProducts = async (search, page) => {
	return await getData(controller + 'list', {
		pagina: page,
		cantidad: 10,
		texto: search,
	})
		.then((r) => {
			return {
				count: r.cuenta,
				data: r.datos.map(({_id, nombre, precio}) => ({
					_id,
					name: nombre,
					price: precio,
				})),
			};
		})
		.catch((e) => ({count: 0, data: []}));
};

export const selectCategories = async () => {
	return await getData('/Category/list').then((r) => {
		return r.datos.map(({_id, nombre}) => ({value: _id, label: nombre}));
	});
};

export const newCategory = async (category) => {
	return await postData('/Category/register', {categoria: category}).then(
		(r) => {
			return {id: r._id};
		}
	);
};
