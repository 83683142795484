import {Box, Typography} from '@mui/material';
import React from 'react';
import {dp} from '../../theme/utils';

export default function TableRow({headers, item}) {
	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: 'repeat(' + headers.length + ', 10fr) ',
				columnGap: dp(8),
				padding: dp(12),
				paddingLeft: dp(18),
				paddingRight: dp(18),
				position: 'relative',
				alignItems: 'center',
				'::before': {
					content: "''",
					position: 'absolute',
					width: '100%',
					height: '100%',
					bgcolor: 'primary.main',
					opacity: 0,
					borderRadius: dp(16),
					transition: 'all 137ms ease-in',
					pointerEvents: 'none',
				},
				'&:hover::before': {
					opacity: 0.05,
				},
			}}
		>
			{headers.map(({key, component}) => {
				if (component) {
					return <div key={key}>{item[key]}</div>;
				}

				return (
					<div key={key}>
						<Typography variant='body1'>
							{item[key] ? item[key] : '-'}
						</Typography>
					</div>
				);
			})}
		</Box>
	);
}
