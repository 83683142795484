import { Box, Button, Icon, Typography } from "@mui/material";
import React, { memo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { dp } from "../../theme/utils";

function RailButton({ active, to, icon, label }) {
    const _ripple = useRef(null);
    const navigate = useNavigate();
    const handleClick = () => {
        if (to) navigate(to);
    };

    const handleMouseDown = ({ clientX, clientY }) => {
        if (_ripple.current) {
            _ripple.current.start(
                {
                    clientX,
                    clientY,
                },
                // when center is true, the ripple doesn't travel to the border of the container
                { center: false }
            );
        }
    };

    const handleMouseUp = () => {
        _ripple.current.stop({});
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: dp(56),
                gap: dp(4),
                paddingLeft: dp(12),
                paddingRight: dp(12),
                cursor: "pointer",
                color: active ? "text.primary" : "text.secondary",
                "&:hover .MuiButton-root::after": {
                    opacity: 0.08,
                },
                "&:active .MuiButton-root::after": {
                    opacity: 0.12,
                },
            }}
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >
            <Button
                touchRippleRef={_ripple}
                disableTouchRipple
                color={active ? "secondaryContainer" : "secondary"}
                variant={active ? "contained" : "text"}
                size="small"
                sx={{
                    height: dp(32),
                    width: dp(58),
                    padding: 0,
                    minWidth: "auto",
                    position: "relative",
                    overflow: "hidden",
                    "&::after": {
                        content: "''",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        bgcolor: active ? "text.primary" : "text.secondary",
                        opacity: 0,
                        transition: "all 137ms ease",
                    },
                }}
            >
                <Icon>{icon}</Icon>
            </Button>
            {label && (
                <Typography
                    component="span"
                    align="center"
                    sx={{ fontSize: dp(10), fontWeight: 600 }}
                >
                    {label}
                </Typography>
            )}
        </Box>
    );
}

export default memo(RailButton);
