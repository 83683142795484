import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { COOKIE_NAME } from '../config/cookie';
import {
	selectIsLogged,
	setSession
} from '../store/reducers/session';
import { getData } from '../utils/fetch';

export default function Root() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {pathname} = useLocation();
	const isLogged = useSelector(selectIsLogged);
	const [cookie, setCookie] = useCookies([COOKIE_NAME]);

	useEffect(() => {
		(async () => {
			if (isLogged) {
				if (pathname.indexOf('home') === -1) {
					navigate('/home/users', {
						replace: true,
					});
				}
			} else {
				if (cookie[COOKIE_NAME] !== 'null') {
					try {
						const r = await getData('Users/me');

						if (r.estado !== 'Error') {
							dispatch(
								setSession({
									token: cookie[COOKIE_NAME],
									nivel: r.nivel,
								})
							);

							return;
						}

						setCookie(COOKIE_NAME, null, {
							path: '/',
						});
					} catch (e) {
						setCookie(COOKIE_NAME, null, {
							path: '/',
						});
					}
				}
				if (pathname !== '/login') {
					navigate('/login', {replace: true});
				}
			}
		})();
	}, [isLogged, cookie]);

	return <Outlet />;
}
