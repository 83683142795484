import {Typography} from '@mui/material';
import React from 'react';
import {useParams} from 'react-router-dom';
import {useAlert} from '../../../components/alert/AlertProvider';
import PageDialog from '../../../components/modal/PageDialog';
import useMutationDelete from '../../../hooks/useMutationDelete';
import {deleteUser} from './utils/requests';

export default function UserDelete() {
	const {desc, id} = useParams();
	const {fireSuccessInDialog, fireErrorInDialog} = useAlert();

	const mutation = useMutationDelete({
		deleteRequest: deleteUser,
		fireSuccess: fireSuccessInDialog,
		fireError: fireErrorInDialog,
		key: ['users'],
		id,
	});

	return (
		<PageDialog
			title='¿Estás seguro?'
			titleIcon='delete_sweep'
			doneText={'Continuar'}
			onDone={mutation.mutate}
			loading={mutation.isLoading}
		>
			<Typography variant='body1'>
				Se eliminará el usuario{' '}
				<Typography color='primary' component='span' fontWeight='bold'>
					{desc}
				</Typography>{' '}
				permanentemente.
			</Typography>
		</PageDialog>
	);
}
