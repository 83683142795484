import axios from 'axios';
import { Cookies } from 'react-cookie';
import { COOKIE_NAME } from '../config/cookie';

const cookies = new Cookies();
const instance = axios.create({
	baseURL: 'https://api.maquinas.intimissense.com/',
});

const catcherStatus = (r) => {
	if (r.estado === 'Error') {
		throw new Error(r.descripcion);
	}

	return r;
};

const catcher = (e) => {
	if (e.response) {
		if (e.response.status === 401) {
			cookies.set(COOKIE_NAME, null, {path: '/'});
			window.location.reload();
		}

		const message = e.response.data ? e.response.data.message : '';
		throw new Error(
			typeof message === 'string' ? message : message.join(', ')
		);
	}
	throw e;
};

export const postData = async (url, data) => {
	return await instance
		.post(url, data, {
			headers: {
				Authorization: 'Bearer ' + cookies.get(COOKIE_NAME),
			},
		})
		.then((r) => r.data)
		.then(catcherStatus)
		.catch(catcher);
};

export const putData = async (url, data) => {
	return await instance
		.put(url, data, {
			headers: {
				Authorization: 'Bearer ' + cookies.get(COOKIE_NAME),
			},
		})
		.then((r) => r.data)
		.then(catcherStatus)
		.catch(catcher);
};

export const postFiles = async (url, data) => {
	const fd = new FormData();
	Object.keys(data).forEach((k) => {
		if (Array.isArray(data[k])) {
			data[k].forEach((v) => {
				fd.append(k + '[]', v);
			});
		} else {
			fd.append(k, data[k]);
		}
	});

	return await instance
		.post(url, fd, {
			headers: {
				Authorization: 'Bearer ' + cookies.get(COOKIE_NAME),
			},
		})
		.then((r) => r.data)
		.then(catcherStatus)
		.catch(catcher);
};

export const putFiles = async (url, data) => {
	const fd = new FormData();
	Object.keys(data).forEach((k) => {
		fd.append(k, data[k]);
	});

	return await instance
		.put(url, fd, {
			headers: {
				Authorization: 'Bearer ' + cookies.get(COOKIE_NAME),
			},
		})
		.then((r) => r.data)
		.then(catcherStatus)
		.catch(catcher);
};

export const getData = async (url, query) => {
	return await instance
		.get(url, {
			params: query,
			headers: {
				Authorization: 'Bearer ' + cookies.get(COOKIE_NAME),
			},
		})
		.then((r) => r.data)
		.then(catcherStatus)
		.catch(catcher);
};

export const getFile = async (url, query) => {
	return await instance
		.get(url, {
			params: query,
			headers: {
				Authorization: 'Bearer ' + cookies.get(COOKIE_NAME),
			},
			responseType: 'blob',
		})
		.then((r) => r.data)
		.catch(catcher);
};

export const getBlob = async (url) => {
	return await instance
		.get(url, {
			responseType: 'blob',
			headers: {
				Authorization: 'Bearer ' + cookies.get(COOKIE_NAME),
				'Access-Control-Expose-Headers': 'Content-Disposition',
			},
		})
		.then((r) => ({data: r.data, headers: r.headers}))
		.then(catcherStatus)
		.catch(catcher);
};

export const deleteData = async (url, query) => {
	return await instance
		.delete(url, {
			params: query,
			headers: {
				Authorization: 'Bearer ' + cookies.get(COOKIE_NAME),
			},
		})
		.then((r) => r.data)
		.then(catcherStatus)
		.catch(catcher);
};
