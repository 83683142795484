import { Box } from '@mui/material';
import React, { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import CustomFab from '../components/buttons/CustomFab';
import Rail from '../components/navigation/Rail';
import RailButton from '../components/navigation/RailButton';
import { COOKIE_NAME } from '../config/cookie';
import { unsetSession } from '../store/reducers/session';
import { dp } from '../theme/utils';

const routes = [
	{label: 'Usuarios', icon: 'group', to: '/home/users'},
	{label: 'Productos', icon: 'inventory_2', to: '/home/products'},
	{label: 'Firmware', icon: 'memory', to: '/home/firmware'},
	{label: 'Maquinas', icon: 'point_of_sale', to: '/home/machines'},
	{label: 'Ventas', icon: 'dashboard', to: '/home/reports'},
];

export default function Home() {
	const dispatch = useDispatch();
	const [, setCookie] = useCookies([COOKIE_NAME]);
	const {pathname} = useLocation();

	const handleLogout = useCallback(() => {
		setCookie(COOKIE_NAME, null, {path: '/'});
		dispatch(unsetSession());
	}, []);

	return (
		<Box sx={{display: 'flex'}}>
			<Rail>
				<CustomFab
					flat
					color='primaryContainer'
					icon='logout'
					onClick={handleLogout}
				/>
				<Box
					sx={{
						flexGrow: 1,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						gap: dp(12),
					}}
				>
					{routes.map(({label, icon, to}) => (
						<RailButton
							key={to}
							label={label}
							icon={icon}
							to={to}
							active={pathname.indexOf(to) !== -1}
						/>
					))}
				</Box>
			</Rail>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					bgcolor: 'background.default',
					minHeight: '100vh',
				}}
			>
				<Outlet />
			</Box>
		</Box>
	);
}
