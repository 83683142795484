import { Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAlert } from '../../../components/alert/AlertProvider';
import PageDialog from '../../../components/modal/PageDialog';
import useMutationDelete from '../../../hooks/useMutationDelete';
import { deleteFirmware } from './utils/requests';

export default function FirmwareDelete() {
	const {desc, id} = useParams();
	const {fireSuccessInDialog, fireErrorInDialog} = useAlert();
	const mutation = useMutationDelete({
		deleteRequest: deleteFirmware,
		fireSuccess: fireSuccessInDialog,
		fireError: fireErrorInDialog,
		key: ['firmwares'],
		id,
	});

	return (
		<PageDialog
			title='¿Estás seguro?'
			titleIcon='delete_sweep'
			doneText={'Continuar'}
			onDone={mutation.mutate}
		>
			<Typography variant='body1'>
				Se eliminará el firmware{' '}
				<Typography color='primary' component='span' fontWeight='bold'>
					{desc}
				</Typography>{' '}
				permanentemente.
			</Typography>
		</PageDialog>
	);
}
