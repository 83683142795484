import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../feedback/spinner";

export default function AutoRedirect({ to }) {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(to);
    }, []);

    return (
        <>
            <Spinner />
        </>
    );
}
