import {
    Avatar,
    CardActionArea,
    Icon,
    ListItemAvatar,
    ListItemText,
    Menu,
    MenuItem,
    Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { dp } from '../../../../theme/utils';
import { selectProducts } from '../utils/requests';

export default function AddButton({onAdd}) {
	const [menu, setMenu] = useState(null);
	const {data} = useQuery(['products', 'select'], async () => {
		return await selectProducts();
	});

	const handleClose = () => setMenu(null);
	const handleOpen = (event) => {
		setMenu(
			menu === null
				? {
						mouseX: event.clientX + 2,
						mouseY: event.clientY - 6,
				  }
				: null
		);
	};

	return (
		<>
			<CardActionArea
				sx={{
					flex: 1,
					minHeight: dp(220),
					borderRadius: dp(12),
					bgcolor: 'background.paper.variant',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					gap: dp(12),
				}}
				onClick={handleOpen}
			>
				<Icon sx={{fontSize: dp(32), color: 'secondary.main'}}>
					add_circle
				</Icon>
				<Typography color='secondary'>Agregar producto</Typography>
			</CardActionArea>
			<Menu
				open={menu !== null}
				onClose={handleClose}
				anchorReference='anchorPosition'
				anchorPosition={
					menu !== null
						? {top: menu.mouseY, left: menu.mouseX}
						: undefined
				}
				PaperProps={{
					sx: {
						bgcolor: 'background.paper.default',
						maxHeight: dp(300),
					},
				}}
			>
				{!data && (
					<MenuItem onClick={onAdd}>
						<ListItemText
							primary={'No hay productos registrados'}
						/>
					</MenuItem>
				)}
				{data &&
					data.map(({value, label}) => (
						<MenuItem
							key={'addbutton_' + value}
							onClick={(e) => {
								onAdd(value);
								handleClose();
							}}
						>
							<ListItemAvatar>
								<Avatar
									src={
										'https://api.maquinas.intimissense.com/Product/downloadFirstImage/' +
										value
									}
								/>
							</ListItemAvatar>
							<ListItemText primary={label} />
						</MenuItem>
					))}
			</Menu>
		</>
	);
}
