import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

export default function Policies() {
	const {id} = useParams();
	const _link = useRef(null);
	const [opened, setOpened] = useState(false);

	return (
		<div>Aqui van los terminos y condiciones</div>
	);
}
