import {
    Checkbox as MuiCheckbox,
    FormControlLabel,
    Typography,
} from "@mui/material";
import React from "react";

export default function Checkbox({
    value,
    onChange,
    color,
    children,
    variant,
}) {
    return (
        <FormControlLabel
            control={
                <MuiCheckbox
                    color={color}
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                />
            }
            label={
                <Typography variant={variant || "caption"}>
                    {children}
                </Typography>
            }
        />
    );
}
