import React from 'react';
import OutlinedField from '../../../components/input/OutlinedField';
import PageDialog from '../../../components/modal/PageDialog';
import useMachine from './utils/useMachine';

export default function MachineForm() {
	const {
		id,
		setId,
		location,
		setLocation,
		phone,
		setPhone,
		isLoading,
		isEdit,
		handleSubmit,
		slots,
		setSlots,
	} = useMachine();

	return (
		<PageDialog
			title={isEdit ? 'Editar maquina' : 'Nueva maquina'}
			titleIcon={isEdit ? 'edit_document' : 'note_add'}
			onDone={handleSubmit}
			loading={isLoading}
		>
			<OutlinedField
				label={'Nombre'}
				icon='badge'
				value={id}
				onChange={setId}
			/>
			<OutlinedField
				label={'Ubicación'}
				icon='pin_drop'
				value={location}
				onChange={setLocation}
			/>
			<OutlinedField
				label={'Teléfono'}
				icon='call'
				value={phone}
				onChange={setPhone}
			/>
			<OutlinedField
				label={'Espacios'}
				icon='grid_view'
				value={slots}
				onChange={setSlots}
				type='number'
			/>
		</PageDialog>
	);
}
