import {Box} from '@mui/material';
import React from 'react';
import CustomFab from '../../components/buttons/CustomFab';
import {dp} from '../../theme/utils';

export default function TablePagination({count, rowsPerPage, page, onPage}) {
	const pages = Math.ceil(count / rowsPerPage);

	const handleClick = (val) => (e) => onPage(val);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				gap: dp(4),
			}}
		>
			<CustomFab
				xsmall
				flat
				color='transparent'
				outlined={page - 1 > 0}
				icon='chevron_left'
				disabled={page - 1 <= 0}
				onClick={handleClick(page - 1)}
			></CustomFab>
			{page > 2 && (
				<CustomFab
					xsmall
					flat
					color={'transparent'}
					outlined
					onClick={handleClick(1)}
				>
					1
				</CustomFab>
			)}
			{page - 2 > 1 && (
				<CustomFab xsmall flat color={'transparent'} outlined>
					..
				</CustomFab>
			)}
			{page > 1 && (
				<CustomFab
					xsmall
					flat
					color={'transparent'}
					outlined
					onClick={handleClick(page - 1)}
				>
					{page - 1}
				</CustomFab>
			)}
			<CustomFab xsmall flat color='secondaryContainer' outlined>
				{page}
			</CustomFab>
			{page < pages && (
				<CustomFab
					xsmall
					flat
					color={'transparent'}
					outlined
					onClick={handleClick(page + 1)}
				>
					{page + 1}
				</CustomFab>
			)}
			{page + 2 < pages && (
				<CustomFab xsmall flat color={'transparent'} outlined>
					..
				</CustomFab>
			)}
			{page + 1 < pages && (
				<CustomFab
					xsmall
					flat
					color={'transparent'}
					outlined
					onClick={handleClick(pages)}
				>
					{pages}
				</CustomFab>
			)}
			<CustomFab
				xsmall
				flat
				color='transparent'
				outlined={pages > page}
				icon='chevron_right'
				disabled={pages <= page}
				onClick={handleClick(page + 1)}
			></CustomFab>
		</Box>
	);
}
