import { Button, CircularProgress, Icon } from '@mui/material';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Table from '../../../components/table/Table';
import Header from '../../../components/typography/Header';
import Container from '../../../components/utils/Container';
import purchases from '../../../config/headers/purchases';
import useQueryList from '../../../hooks/useQueryList';
import { downloadReport, listPurchase } from './utils/requests';

export default function Reports() {
	const {count, page, setPage, data, rowsPerPage} = useQueryList(['users'], {
		listData: listPurchase,
	});
	const [loading, setLoading] = useState(false);

	const handleDownload = async () => {
		setLoading(true);
		const data = await downloadReport();
		const href = URL.createObjectURL(data);
		const link = document.createElement('a');

		link.href = href;
		link.setAttribute('download', 'Reporte de compras.csv');
		document.body.appendChild(link);
		link.click();
		link.remove();
		setLoading(false);
	};

	return (
		<>
			<Container>
				<Header title='Ventas'>
					<Button
						color='primary'
						endIcon={
							loading ? (
								<CircularProgress size={12} />
							) : (
								<Icon>download</Icon>
							)
						}
						onClick={handleDownload}
						disabled={loading}
					>
						Descargar reporte
					</Button>
				</Header>
				{/* <SearchField value={search} onChange={setSearch} /> */}
				<Table
					headers={purchases}
					data={data ? data.data : []}
					count={count}
					page={page}
					onPage={setPage}
					rowsPerPage={rowsPerPage}
				/>
			</Container>
			<Outlet />
		</>
	);
}
