import { Fab, Icon } from "@mui/material";
import React from "react";
import { dp } from "../../theme/utils";

export default function CustomFab({
    children,
    color,
    flat,
    onClick,
    small,
    large,
    icon,
    xsmall,
    outlined,
    square,
    ...props
}) {
    return (
        <Fab
            {...props}
            onClick={onClick}
            color={color}
            sx={{
                borderRadius: square
                    ? 0
                    : dp(xsmall ? 10 : small ? 12 : large ? 28 : 16),
                position: "relative",
                overflow: "hidden",
                transition: "all 137ms ease",
                boxShadow: flat ? 0 : 3,
                width: dp(xsmall ? 36 : small ? 40 : large ? 96 : 56),
                height: dp(xsmall ? 36 : small ? 40 : large ? 96 : 56),
                border: outlined ? "solid" : "solid",
                borderWidth: outlined ? 1 : 0,
                borderColor: "outline.main",
                "&::after": {
                    content: "''",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    bgcolor: "none",
                },
                "&:hover": {
                    bgcolor: color + ".main",
                    boxShadow: flat ? 0 : 2,
                    borderRadius: square
                        ? 0
                        : dp(xsmall ? 8 : small ? 10 : large ? 24 : 12),
                },
                "&:hover::after": {
                    bgcolor: "text.primary",
                    opacity: 0.08,
                },
                "&:active": {
                    boxShadow: "none",
                },
                "&:active::after": {
                    bgcolor: color + ".main",
                },
            }}
        >
            {icon ? (
                <Icon
                    sx={{
                        fontSize: dp(
                            xsmall ? 16 : small ? 24 : large ? 36 : 24
                        ),
                    }}
                >
                    {icon}
                </Icon>
            ) : (
                children
            )}
        </Fab>
    );
}
