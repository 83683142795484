import { Box } from '@mui/material';
import React from 'react';
import { dp } from '../../theme/utils';
import TableHeader from './TableHeader';
import TablePagination from './TablePagination';
import TableRow from './TableRow';

export default function Table({
	headers,
	data,
	count,
	rowsPerPage,
	page,
	onPage,
	options,
}) {
	const calculated_headers = options
		? [
				...headers,
				{
					label: 'Opciones',
					key: 'options',
					component: true,
					shrink: true,
				},
		  ]
		: headers;

	return (
		<>
			<Box
				sx={{
					flexGrow: 1,
					display: 'flex',
					flexDirection: 'column',
					gap: dp(8),
				}}
			>
				<TableHeader headers={calculated_headers} />
				{data.length === 0 && (
					<TableRow
						headers={[{key: 'error'}]}
						item={{error: 'Sin información'}}
					/>
				)}
				{data.map((item, i) => (
					<TableRow
						key={item._id + '_' + i}
						headers={calculated_headers}
						item={item}
					/>
				))}
			</Box>
			<TablePagination
				count={count}
				rowsPerPage={rowsPerPage ? rowsPerPage : 10}
				page={page}
				onPage={onPage}
			/>
		</>
	);
}
