import { Button, Icon, IconButton } from '@mui/material';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import DeleteIconButton from '../../../components/buttons/DeleteIconButton';
import EditIconButton from '../../../components/buttons/EditIconButton';
import SearchField from '../../../components/input/SearchField';
import Table from '../../../components/table/Table';
import Header from '../../../components/typography/Header';
import Container from '../../../components/utils/Container';
import machines from '../../../config/headers/machines';
import useQueryList from '../../../hooks/useQueryList';
import addOptions from '../../../utils/addOptions';
import { QUERY_KEY } from './config/consts';
import { listMachines } from './utils/requests';

export default function Machines() {
	const navigate = useNavigate();
	const {search, setSearch, count, page, setPage, data, rowsPerPage} =
		useQueryList([QUERY_KEY], {
			listData: listMachines,
		});

	return (
		<>
			<Container>
				<Header title='Maquinas'>
					<Button
						color='primary'
						endIcon={<Icon>add</Icon>}
						onClick={(e) => navigate('/home/machines/form')}
					>
						Nueva maquina
					</Button>
				</Header>
				<SearchField value={search} onChange={setSearch} />
				<Table
					options
					headers={machines}
					data={addOptions(
						(data ? data.data : []).map((v) => ({
							...v,
							online: v.online ? (
								<Icon color='success' fontSize='small'>
									wifi
								</Icon>
							) : (
								<Icon color='error' fontSize='small'>
									wifi_off
								</Icon>
							),
						})),
						({_id, id}) => [
							<IconButton
								size='small'
								key={'refill_' + _id}
								onClick={(e) =>
									navigate(
										'/home/machines/refill/' +
											_id +
											'/' +
											id
									)
								}
								color='customOne'
							>
								<Icon fontSize='small'>autorenew</Icon>
							</IconButton>,
							<IconButton
								size='small'
								key={'qr_' + _id}
								onClick={(e) =>
									window.open(
										'https://api.maquinas.intimissense.com/Machine/downloadQR/' +
											_id,
										'_blank'
									)
								}
							>
								<Icon fontSize='small'>qr_code</Icon>
							</IconButton>,
							<IconButton
								size='small'
								color='primary'
								key={'assign_' + _id}
								onClick={(e) =>
									navigate(
										'/home/machines/assign/' +
											_id +
											'/' +
											id
									)
								}
							>
								<Icon fontSize='small'>inventory</Icon>
							</IconButton>,
							<EditIconButton
								key={'edit_' + _id}
								onClick={(e) =>
									navigate('/home/machines/form/' + _id)
								}
							/>,
							<DeleteIconButton
								key={'delete_' + _id}
								onClick={(e) =>
									navigate(
										'/home/machines/delete/' +
											_id +
											'/' +
											id
									)
								}
							/>,
						]
					)}
					count={count}
					page={page}
					onPage={setPage}
					rowsPerPage={rowsPerPage}
				/>
			</Container>
			<Outlet />
		</>
	);
}
