import {Button, Icon} from '@mui/material';
import React from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import DeleteIconButton from '../../../components/buttons/DeleteIconButton';
import EditIconButton from '../../../components/buttons/EditIconButton';
import SearchField from '../../../components/input/SearchField';
import Table from '../../../components/table/Table';
import Header from '../../../components/typography/Header';
import Container from '../../../components/utils/Container';
import users from '../../../config/headers/users';
import useQueryList from '../../../hooks/useQueryList';
import addOptions from '../../../utils/addOptions';
import {listUsers} from './utils/requests';

export default function Users() {
	const navigate = useNavigate();
	const {search, setSearch, count, page, setPage, data, rowsPerPage} =
		useQueryList(['users'], {
			listData: listUsers,
		});

	return (
		<>
			<Container>
				<Header title='Usuarios'>
					<Button
						color='primary'
						endIcon={<Icon>add</Icon>}
						onClick={(e) => navigate('/home/users/form')}
					>
						Nuevo usuario
					</Button>
				</Header>
				<SearchField value={search} onChange={setSearch} />
				<Table
					options
					headers={users}
					data={addOptions(data ? data.data : [], ({_id, name}) => [
						<EditIconButton
							key={'edit_' + _id}
							onClick={(e) => navigate('/home/users/form/' + _id)}
						/>,
						<DeleteIconButton
							key={'delete_' + _id}
							onClick={(e) =>
								navigate(
									'/home/users/delete/' + _id + '/' + name
								)
							}
						/>,
					])}
					count={count}
					page={page}
					onPage={setPage}
					rowsPerPage={rowsPerPage}
				/>
			</Container>
			<Outlet />
		</>
	);
}
