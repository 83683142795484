import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { dp } from "../../theme/utils";

export default memo(function Header({ title, children }) {
    return (
        <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    mb: dp(8),
                }}
            >
                <Typography variant="caption">Movei</Typography>
                <Typography variant="h5">{title}</Typography>
            </Box>
            <div style={{ flexGrow: 1 }}></div>
            {children}
        </Box>
    );
});
