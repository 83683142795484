import { Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAlert } from '../../../components/alert/AlertProvider';
import PageDialog from '../../../components/modal/PageDialog';
import { QUERY_KEY } from './config/consts';
import { refillMachine } from './utils/requests';

export default function MachineRefill() {
	const {desc, id} = useParams();
	const {fireSuccessInDialog, fireErrorInDialog} = useAlert();
	const queryClient = useQueryClient();

	const mutation = useMutation(async (id) => {
		console.log(id);
		return await refillMachine(id);
	});

	const handleRefill = () =>
		mutation.mutate(id, {
			onSuccess: (r) => {
				fireSuccessInDialog({
					content:
						'En un momento se abrirán los casilleros de la maquina',
				});
				queryClient.invalidateQueries([QUERY_KEY]);
			},
			onError: (e) => {
				fireErrorInDialog({content: e.message});
			},
		});

	return (
		<PageDialog
			title='¿Estás seguro?'
			titleIcon='autorenew'
			doneText={'Continuar'}
			onDone={handleRefill}
			loading={mutation.isLoading}
		>
			<Typography variant='body1'>
				Continuar abrirá los casilleros de la maquina{' '}
				<Typography color='primary' component='span' fontWeight='bold'>
					{desc}
				</Typography>
				.
			</Typography>
		</PageDialog>
	);
}
