import {Icon, IconButton} from '@mui/material';
import React from 'react';

export default function DeleteIconButton({onClick}) {
	return (
		<IconButton color='error' size='small' onClick={onClick}>
			<Icon fontSize='small'>delete</Icon>
		</IconButton>
	);
}
