import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CircularProgress,
	Icon,
} from '@mui/material';
import {useMutation} from '@tanstack/react-query';
import React, {useState} from 'react';
import {useCookies} from 'react-cookie';
import {useDispatch} from 'react-redux';
import {useAlert} from '../../components/alert/AlertProvider';
import Checkbox from '../../components/input/Checkbox';
import OutlinedField from '../../components/input/OutlinedField';
import {COOKIE_NAME} from '../../config/cookie';
import {setSession} from '../../store/reducers/session';
import {dp} from '../../theme/utils';
import {postData} from '../../utils/fetch';

export default function Login() {
	const dispatch = useDispatch();
	const {fireError} = useAlert();
	const [user, setUser] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [, setCookie] = useCookies([COOKIE_NAME]);
	const mutation = useMutation(async ({user: usuario, password}) => {
		const r = await postData('Login/login', {
			usuario,
			password,
		})
			.then((r) => {
				if (r.estado === 'Error') {
					throw new Error(r.descripcion);
				}

				return r;
			})
			.catch((e) => {
				throw new Error(e.message);
			});

		return r;
	});

	const handleSubmit = async (e) => {
		e.preventDefault();

		mutation.mutate(
			{
				user,
				password,
			},
			{
				onError: (e) => {
					fireError({content: e.message});
				},
				onSuccess: async (r) => {
					dispatch(setSession({token: r.token, nivel: r.nivel}));
					setCookie(COOKIE_NAME, r.token, {path: '/'});
				},
			}
		);
	};

	return (
		<Box
			sx={{
				width: '100vw',
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				'&::before': {
					content: "''",
					width: '100%',
					height: '100%',
					position: 'absolute',
					bgcolor: 'primary.main',
					opacity: 0.05,
					pointerEvents: 'none',
					zIndex: -1,
				},
			}}
		>
			<form onSubmit={handleSubmit}>
				<Card
					variant='outlined'
					sx={{
						width: dp(350),
						borderRadius: dp(12),
						padding: dp(16),
						color: 'text.secondary',
						borderColor: 'outline.main',
						bgcolor: 'background.paper.default',
					}}
				>
					<CardHeader
						sx={{padding: 0}}
						title={'Inicia sesión'}
						subheader={'Movei'}
					/>
					<CardContent
						sx={{
							paddingTop: dp(28),
							paddingBottom: dp(28),
							display: 'flex',
							flexDirection: 'column',
							gap: dp(28),
						}}
					>
						<OutlinedField
							variant='outlined'
							color='primary'
							label='Usuario'
							icon='face'
							value={user}
							onChange={setUser}
						/>
						<OutlinedField
							variant='outlined'
							color='primary'
							label='Contraseña'
							type={showPassword ? 'text' : 'password'}
							icon='password'
							value={password}
							onChange={setPassword}
						/>
						<Checkbox
							color='primary'
							value={showPassword}
							onChange={setShowPassword}
						>
							Mostrar contraseñas
						</Checkbox>
					</CardContent>
					<CardActions>
						<div style={{flexGrow: 1}}></div>
						<Button
							endIcon={
								mutation.isLoading ? (
									<CircularProgress size={dp(16)} />
								) : (
									<Icon>arrow_forward</Icon>
								)
							}
							color='primary'
							variant='text'
							type='submit'
							disabled={mutation.isLoading}
						>
							Continuar
						</Button>
					</CardActions>
				</Card>
			</form>
		</Box>
	);
}
