import {
	deleteData,
	getData, postFiles
} from '../../../../utils/fetch';

const sleep = async (ms) => {
	await new Promise((s) => {
		setTimeout(() => {
			s();
		}, ms);
	});
};

export const newFirmware = async ({version, file}) => {
	return await postFiles('/Firmware/register', {
		version: version,
		archivo: file,
	});
};

export const deleteFirmware = async (id) => {
	return await deleteData('/Firmware/remove/' + id);
};

export const listFirmware = async (search, page) => {
	return await getData('/Firmware/list', {
		texto: search,
		pagina: page,
		cantidad: 10,
	}).then(({datos, cuenta}) => {
		return {
			count: cuenta,
			data: datos.map(({_id, version, archivo}) => ({
				_id,
				version,
				file: archivo,
			})),
		};
	});
};
