import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAlert } from '../components/alert/AlertProvider';

export default function useQueryForm(key, {getRequest, dataSetters, onError}) {
	const {fireErrorInDialog} = useAlert();
	const {id} = useParams();
	const {data, isLoading, error} = useQuery(
		[...key, 'get', id],
		async ({queryKey: [, , id]}) => {
			if (id) {
				return await getRequest(id);
			}
			return null;
		},
		{
			retry: false,
		}
	);

	useEffect(() => {
		if (data) {
			Object.keys(dataSetters).forEach((k) => {
				if (data[k]) {
					dataSetters[k](data[k]);
				}
			});
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (onError) {
				onError(error);
			} else {
				fireErrorInDialog({
					content: error.message,
				});
			}
		}
	}, [error]);

	return {
		isLoading,
		isEdit: Boolean(id),
		id,
	};
}
