import React, {useRef} from 'react';
import OutlinedField from './OutlinedField';

const renderValue = ({value, multiple}) => {
	if (multiple) {
		return value.length > 0 ? `${value.length} archivos seleccionados` : '';
	}

	return value ? value.name : '';
};

export default function OutlinedFileField({
	label,
	placeholder,
	icon,
	value,
	onChange,
	multiple,
	...props
}) {
	const _fileInput = useRef(null);

	return (
		<>
			<OutlinedField
				icon={icon}
				label={label}
				placeholder={placeholder}
				value={renderValue({value, multiple})}
				onClick={(e) => {
					if (_fileInput.current) {
						_fileInput.current.click();
					}
				}}
				disableClear
				readOnly
				{...props}
			/>
			<input
				multiple={multiple}
				type='file'
				style={{display: 'none'}}
				ref={_fileInput}
				onChange={(e) =>
					multiple
						? onChange(e.target.files)
						: onChange(e.target.files[0])
				}
			/>
		</>
	);
}
