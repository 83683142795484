import { Box, Typography } from '@mui/material';
import React from 'react';
import { dp } from '../../theme/utils';

export default function TableHeader({headers}) {
	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: 'repeat(' + headers.length + ', 1fr)',
				columnGap: dp(8),
				padding: dp(12),
				paddingLeft: dp(18),
				paddingRight: dp(18),
				position: 'relative',
				'::before': {
					content: "''",
					position: 'absolute',
					width: '100%',
					height: '100%',
					bgcolor: 'secondary.main',
					opacity: 0,
					borderRadius: dp(16),
					pointerEvents: 'none',
				},
				'&:hover::before': {
					opacity: 0.08,
				},
			}}
		>
			{headers.map(({key, label}) => (
				<Typography variant='body2' fontWeight={'bold'} key={key}>
					{label}
				</Typography>
			))}
		</Box>
	);
}
