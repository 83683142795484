import { deleteData, getData, postData, putData } from '../../../../utils/fetch';

export const getUser = async (id) => {
	return await getData('/Users/get/' + id).then(
		({usuario: {nombre, password, usuario, email}}) => {
			return {
				user: usuario,
				name: nombre,
				email,
				password,
			};
		}
	);
};

export const newUser = async ({user, name, password, email}) => {
	return await postData('/Users/register', {
		usuario: user,
		nombre: name,
		nivel: 1,
		email,
		password,
	});
};

export const editUser = async ({user, name, password, email, id}) => {
	return await putData('/Users/update/' + id, {
		usuario: user,
		nombre: name,
		nivel: 1,
		email,
		password,
	});
};

export const deleteUser = async (id) => {
	return await deleteData('/Users/remove/' + id);
};

export const listUsers = async (search, page) => {
	return await getData('/Users/list', {
		pagina: page,
		cantidad: 10,
		texto: search,
	})
		.then((r) => {
			return {
				count: r.cuenta,
				data: r.datos.map(({nombre, nivel, email, usuario, _id}) => ({
					name: nombre,
					level: nivel,
					user: usuario,
					email,
					_id,
				})),
			};
		})
		.catch((e) => ({count: 0, data: []}));
};
