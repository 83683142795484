import { Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAlert } from '../../../components/alert/AlertProvider';
import PageDialog from '../../../components/modal/PageDialog';
import useMutationDelete from '../../../hooks/useMutationDelete';
import { QUERY_KEY } from './config/consts';
import { deleteMachine } from './utils/requests';

export default function MachineDelete() {
	const {desc, id} = useParams();
	const {fireSuccessInDialog, fireErrorInDialog} = useAlert();

	const mutation = useMutationDelete({
		deleteRequest: deleteMachine,
		fireSuccess: fireSuccessInDialog,
		fireError: fireErrorInDialog,
		key: [QUERY_KEY],
		id,
	});

	return (
		<PageDialog
			title='¿Estás seguro?'
			titleIcon='delete_sweep'
			doneText={'Continuar'}
			onDone={mutation.mutate}
			loading={mutation.isLoading}
		>
			<Typography variant='body1'>
				Se eliminará la maquina{' '}
				<Typography color='primary' component='span' fontWeight='bold'>
					{desc}
				</Typography>{' '}
				permanentemente.
			</Typography>
		</PageDialog>
	);
}
