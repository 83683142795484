import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useAlert } from '../../../../components/alert/AlertProvider';
import useMutationForm from '../../../../hooks/useMutationForm';
import useQueryForm from '../../../../hooks/useQueryForm';
import {
	editProduct,
	getProduct,
	newCategory,
	newProduct,
	selectCategories
} from './requests';

function useProductData() {
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [price, setPrice] = useState('');
	const [categories, setCategories] = useState([]);
	const [images, setImages] = useState([]);

	return {
		name,
		setName,
		description,
		setDescription,
		price,
		setPrice,
		images,
		setImages,
		categories,
		setCategories,
	};
}

export default function useProduct() {
	// Data hooks
	const data = useProductData();
	const {
		setName,
		setDescription,
		setPrice,
		setImages,
		setCategories,
		name,
		description,
		price,
		images,
		categories,
	} = data;

	// Custom hooks
	const queryClient = useQueryClient();
	const {fireSuccessInDialog, fireError} = useAlert();
	const {
		id,
		isEdit,
		isLoading: isLoadingQuery,
	} = useQueryForm(['products'], {
		getRequest: getProduct,
		dataSetters: {
			name: setName,
			description: setDescription,
			price: setPrice,
			images: setImages,
			categories: setCategories,
		},
	});
	const {data: categoriesOptions} = useQuery(
		['categories'],
		async () => {
			return await selectCategories();
		},
		{
			keepPreviousData: true,
		}
	);
	const categoriesMutation = useMutation(async (category) => {
		return await newCategory(category);
	});
	const mutation = useMutationForm({
		addRequest: newProduct,
		editRequest: editProduct,
		idObject: isEdit ? {id} : undefined,
		fireSuccess: fireSuccessInDialog,
		key: ['products'],
		fireError,
	});

	const handleSubmit = () =>
		mutation.mutate({
			name,
			description,
			price,
			images,
			categories: categories.map(({value}) => value),
		});

	const handleAddCategory = (category) =>
		categoriesMutation.mutate(category, {
			onSuccess: (r) => {
				setCategories((v) => [...v, {value: r.id, label: category}]);
				queryClient.invalidateQueries(['categories']);
			},
			onError: (e) => {
				fireError({
					title: 'No se creo la categoría',
					content: e.message,
				});
			},
		});

	return {
		...data,
		handleSubmit,
		isLoading: isLoadingQuery || mutation.isLoading,
		isEdit,
		categoriesOptions,
		handleAddCategory,
		isNewCategoryLoading: categoriesMutation.isLoading,
	};
}
