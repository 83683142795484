import { useTheme } from "@mui/material";
import React from "react";
import { SyncLoader } from "react-spinners";

export default function Spinner({ dense }) {
    const theme = useTheme();

    return (
        <div
            style={{
                marginTop: theme.spacing(dense ? 2 : 6),
                marginBottom: theme.spacing(dense ? 2 : 6),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: theme.palette.primary.main,
            }}
        >
            <SyncLoader color={theme.palette.primary.main} />
        </div>
    );
}
