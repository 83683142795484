import { useState } from 'react';
import { useAlert } from '../../../../components/alert/AlertProvider';
import useMutationForm from '../../../../hooks/useMutationForm';
import { newFirmware } from './requests';

function useUserData() {
	const [version, setVersion] = useState('');
	const [file, setFile] = useState(null);

	return {
		version,
		setVersion,
		file,
		setFile,
	};
}

export default function useFirmware() {
	// Data hooks
	const data = useUserData();
	const {version, file} = data;

	// Custom hooks
	const {fireSuccessInDialog, fireError} = useAlert();
	const mutation = useMutationForm({
		addRequest: newFirmware,
		fireSuccess: fireSuccessInDialog,
		fireError: fireError,
		key: ['firmwares']
	});

	const handleSubmit = () => mutation.mutate({version, file});
	
	return {
		...data,
		handleSubmit,
	};
}
