import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export default function useQueryList(keys, {listData}) {
	const [search, setSearch] = useState('');
	const [rowsPerPage] = useState(10);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);
	const {data} = useQuery(
		[...keys, search, page],
		async ({queryKey: [, searchQuery, pageQuery]}) => {
			return await listData(searchQuery, pageQuery);
		},
		{
			keepPreviousData: true,
			refetchInterval: 20000,
		}
	);

	useEffect(() => {
		if (data) {
			setCount(data.count);
			if (page > Math.ceil(data.count / rowsPerPage) + 1) {
				setPage(1);
			}
		}
	}, [data, rowsPerPage, page]);

	return {
		search,
		setSearch,
		rowsPerPage,
		page,
		setPage,
		count,
		data,
	};
}
