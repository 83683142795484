import { Box, Drawer } from "@mui/material";
import React from "react";
import { dp } from "../../theme/utils";

const defaultWidth = 80;
export default function Rail({ children, railWidth }) {
    return (
        <Drawer
            sx={{
                width: dp(railWidth ? railWidth : defaultWidth),
                flexShrink: 0,
                border: "none",
                "& .MuiDrawer-paper": {
                    width: dp(railWidth ? railWidth : defaultWidth),
                    gap: dp(12),
                    display: "flex",
                    boxSizing: "border-box",
                    overflow: "hidden",
                    bgcolor: "background.default",
                    // borderColor: "secondaryContainer.main",
                    border: 'none',
                    paddingY: dp(24),
                },
            }}
            variant="permanent"
            anchor="left"
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    alignItems: "center",
                }}
            >
                {children}
            </Box>
        </Drawer>
    );
}
