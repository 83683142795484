import {
	Autocomplete as MuiAutocomplete,
	Checkbox,
	Chip,
	CircularProgress,
	Icon,
	ListItem,
	ListItemIcon,
	ListItemText,
	TextField,
	useTheme
} from '@mui/material';
import React, { useState } from 'react';
import { dp } from '../../theme/utils';

export default function Autocomplete({
	value,
	onChange,
	options,
	label,
	placeholder,
	onAddOption,
	loading,
}) {
	const theme = useTheme();
	const [filter, setFilter] = useState('');

	return (
		<MuiAutocomplete
			multiple
			options={options}
			disableCloseOnSelect
			getOptionLabel={(option) => option.label}
			isOptionEqualToValue={(option, val) =>
				val === '' ? false : option.value === val.value
			}
			renderOption={(props, option, {selected}) => (
				<li {...props}>
					<Checkbox
						color='secondary'
						style={{marginRight: 8}}
						checked={selected}
					/>
					{option.label}
				</li>
			)}
			renderTags={(val, getTagProps) =>
				val
					.filter((v) => v !== '')
					.map((option, index) => (
						<Chip
							label={option.label}
							color='secondaryContainer'
							sx={{borderRadius: dp(8)}}
							deleteIcon={<Icon fontSize='small'>close</Icon>}
							{...getTagProps({index})}
						/>
					))
			}
			renderInput={(params) => (
				<TextField
					variant='outlined'
					label={label}
					placeholder={placeholder}
					sx={{
						'& .MuiOutlinedInput-root': {
							'& fieldset': {
								borderColor: 'outline.main',
							},
							'&: fieldset': {
								borderColor: 'text.secondary',
							},
						},
					}}
					{...params}
				/>
			)}
			ListboxProps={{
				style: {
					backgroundColor: theme.palette.background.paper.default,
				},
			}}
			onChange={(e, val) => {
				onChange(val);
			}}
			inputValue={filter}
			onInputChange={(e, v) => setFilter(v)}
			value={value}
			clearOnBlur={false}
			noOptionsText={
				<ListItem
					dense
					button
					sx={{
						bgcolor: 'background.paper.default',
						cursor: 'pointer',
						'&:hover': {
							bgcolor: 'background.paper.variant',
						},
					}}
					onClick={
						loading
							? undefined
							: (e) => {
									onAddOption(filter);
									setFilter('');
							  }
					}
				>
					<ListItemIcon>
						{loading ? (
							<CircularProgress size={dp(16)} />
						) : (
							<Icon>add</Icon>
						)}
					</ListItemIcon>
					<ListItemText primary='Agregar categoria' />
				</ListItem>
			}
		/>
	);
}
