import React from 'react';
import Checkbox from '../../../components/input/Checkbox';
import OutlinedField from '../../../components/input/OutlinedField';
import PageDialog from '../../../components/modal/PageDialog';
import useUser from './utils/useUser';

export default function UserForm() {
	const {
		user,
		setUser,
		password,
		setPassword,
		confirmPassword,
		setConfirmPassword,
		showPassword,
		setShowPassword,
		email,
		setEmail,
		isLoading,
		isEdit,
		handleSubmit,
		name,
		setName,
	} = useUser();

	return (
		<PageDialog
			title={isEdit ? 'Editar usuario' : 'Nuevo usuario'}
			titleIcon={isEdit ? 'edit_document' : 'note_add'}
			doneDisabled={password === '' || password !== confirmPassword}
			onDone={handleSubmit}
			loading={isLoading}
		>
			<OutlinedField
				label={'Nombre'}
				icon='face'
				value={name}
				onChange={setName}
			/>
			<OutlinedField
				label={'Usuario'}
				icon='badge'
				value={user}
				onChange={setUser}
			/>
			<OutlinedField
				label={'Email'}
				icon='alternate_email'
				value={email}
				onChange={setEmail}
			/>
			<OutlinedField
				label={'Contraseña'}
				type={showPassword ? 'text' : 'password'}
				icon='password'
				value={password}
				onChange={setPassword}
			/>
			<OutlinedField
				label={'Confirmar contraseña'}
				type={showPassword ? 'text' : 'password'}
				icon='password'
				value={confirmPassword}
				onChange={setConfirmPassword}
			/>
			<Checkbox value={showPassword} onChange={setShowPassword}>
				Mostrar contraseñas
			</Checkbox>
		</PageDialog>
	);
}
