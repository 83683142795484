import { Box, Icon, InputBase } from '@mui/material';
import React from 'react';
import { dp } from '../../theme/utils';

export default function SearchField({value, onChange}) {
	return (
		<Box
			sx={{
				position: 'relative',
				bottom: 0,
				width: '100%',
				bgcolor: 'white',
				display: 'flex',
				flexDirection: 'row',
				padding: dp(8),
				paddingLeft: dp(12),
				paddingRight: dp(16),
				alignItems: 'center',
				gap: dp(16),
				borderRadius: dp(24),
				marginBottom: dp(16),
				borderWidth: 1,
				borderStyle: 'solid',
				borderColor: 'outline.main',
			}}
		>
			<Icon sx={{fontSize: dp(16)}}>search</Icon>
			<InputBase
				placeholder='Buscar'
				sx={{flexGrow: 1, padding: 0, fontSize: dp(12)}}
				inputProps={{
					style: {
						paddingTop: dp(4),
						paddingBottom: dp(4),
					},
				}}
				size='small'
				value={value}
				onChange={(e) => onChange(e.target.value)}
			/>
		</Box>
	);
}
