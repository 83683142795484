import { Button, Icon } from '@mui/material';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import DeleteIconButton from '../../../components/buttons/DeleteIconButton';
import EditIconButton from '../../../components/buttons/EditIconButton';
import SearchField from '../../../components/input/SearchField';
import Table from '../../../components/table/Table';
import Header from '../../../components/typography/Header';
import Container from '../../../components/utils/Container';
import products from '../../../config/headers/products';
import useQueryList from '../../../hooks/useQueryList';
import addOptions from '../../../utils/addOptions';
import { listProducts } from './utils/requests';

export default function Products() {
	const navigate = useNavigate();
	const {search, setSearch, count, page, setPage, data, rowsPerPage} =
		useQueryList(['products'], {
			listData: listProducts,
		});

	return (
		<>
			<Container>
				<Header title='Productos'>
					<Button
						color='primary'
						endIcon={<Icon>add</Icon>}
						onClick={(e) => navigate('/home/products/form')}
					>
						Nuevo producto
					</Button>
				</Header>
				<SearchField value={search} onChange={setSearch} />
				<Table
					options
					headers={products}
					data={addOptions(
						(data ? data.data : []).map((v) => ({
							...v,
							price: '$ ' + parseFloat(v.price).toFixed(2),
						})),
						({_id, name}) => [
							<EditIconButton
								key={'edit_' + _id}
								onClick={(e) =>
									navigate('/home/products/form/' + _id)
								}
							/>,
							<DeleteIconButton
								key={'delete_' + _id}
								onClick={(e) =>
									navigate(
										'/home/products/delete/' +
											_id +
											'/' +
											name
									)
								}
							/>,
						]
					)}
					count={count}
					page={page}
					onPage={setPage}
					rowsPerPage={rowsPerPage}
				/>
			</Container>
			<Outlet />
		</>
	);
}
