import { Box } from '@mui/material';
import React from 'react';
import { dp } from '../../theme/utils';

export default function Container({children}) {
	return (
		<Box
			sx={{
				display: 'flex',
				minHeight: '100%',
				flexDirection: 'column',
				padding: dp(8),
				gap: dp(16),
			}}
		>
			<Box
				sx={{
					bgcolor: 'none',
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					borderRadius: dp(16),
					padding: dp(24),
					position: 'relative',
					gap: dp(16),
					'&::before': {
						content: "''",
						borderRadius: dp(16),
						top: 0,
						bottom: 0,
						left: 0,
						right: 0,
						position: 'absolute',
						bgcolor: 'primary.main',
						opacity: 0,
					},
				}}
			>
				{children}
			</Box>
		</Box>
	);
}
