import {
	Avatar,
	Box,
	Button,
	CardActions,
	CardContent,
	CardMedia,
	Icon,
	ListItemAvatar,
	ListItemText,
	Menu,
	MenuItem,
	Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { dp } from '../../../../theme/utils';
import { selectProducts } from '../utils/requests';

export default function ProductItem({id, name, onUpdate, onDelete, sold}) {
	const [menu, setMenu] = useState(null);
	const {data} = useQuery(['products', 'select'], async () => {
		return await selectProducts();
	});

	const handleClose = () => setMenu(null);
	const handleOpen = (event) => setMenu(event.currentTarget);

	return (
		<>
			<Box
				sx={{
					bgcolor: 'background.paper.variant',
					borderRadius: dp(12),
				}}
			>
				<CardMedia
					sx={{height: dp(220), borderRadius: dp(12)}}
					src={
						'https://api.maquinas.intimissense.com/Product/downloadFirstImage/' +
						id
					}
					component='img'
				/>
				<CardContent sx={{paddingTop: dp(6), paddingBottom: dp(6)}}>
					<Typography variant='caption' color='textSecondary'>
						{name}
					</Typography>
					{sold && (
						<Typography
							variant='caption'
							color='primary'
							style={{marginLeft: dp(6)}}
						>
							{' '}
							Vendido
						</Typography>
					)}
				</CardContent>
				<CardActions sx={{justifyContent: 'flex-end'}}>
					<Button
						color='secondary'
						variant='text'
						endIcon={<Icon>edit</Icon>}
						onClick={handleOpen}
					>
						Cambiar
					</Button>
					<Button
						endIcon={<Icon>delete</Icon>}
						color='secondary'
						variant='text'
						onClick={onDelete}
					>
						Eliminar
					</Button>
				</CardActions>
			</Box>
			<Menu
				open={Boolean(menu)}
				onClose={handleClose}
				anchorEl={menu}
				PaperProps={{
					sx: {
						bgcolor: 'background.paper.default',
						maxHeight: dp(300),
					},
				}}
			>
				{!data && (
					<MenuItem>
						<ListItemText
							primary={'No hay productos registrados'}
						/>
					</MenuItem>
				)}
				{data &&
					data.map(({value, label}) => (
						<MenuItem
							key={'productitem_' + value}
							onClick={(e) => {
								onUpdate(value);
								handleClose();
							}}
						>
							<ListItemAvatar>
								<Avatar
									src={
										'https://api.maquinas.intimissense.com/Product/downloadFirstImage/' +
										value
									}
								/>
							</ListItemAvatar>
							<ListItemText primary={label} />
						</MenuItem>
					))}
			</Menu>
		</>
	);
}
