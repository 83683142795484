import { createTheme } from "@mui/material";
import { colorPalette, dp } from "./utils";

const theme = createTheme({
    palette: {
        ...colorPalette({
            primary: ["#b90065", "#ffffff", "#ffd9e3", "#3e001e"],
            secondary: ["#74565f", "#ffffff", "#ffd9e3", "#2b151c"],
            tertiary: ["#7d5635", "#ffffff", "#ffdcc2", "#2e1500"],
            error: ["#ba1a1a", "#ffffff", "#ffdad6", "#410002"],
            transparent: ["#ba1a1a00", "#1a1c1e", "#ffdad600", "#41000200"],
            customOne: ["#9b4053", "#ffffff", "#ffd9dd", "#400013"],
        }),
        background: {
            default: "#fffbff",
            paper: {
                default: "#fffbff",
                variant: "#f2dde2",
            },
        },
        outline: {
            main: "#837377",
        },
        text: {
            primary: "#201a1b",
            secondary: "#514347",
        },
        action: {
            hoverOpacity: 0.08,
            focusOpacity: 0.12,
            activatedOpacity: 0.12,
        },
    },
    typography: {
        fontFamily: "'Plus Jakarta Sans', sans-serif",
    },
    components: {
        MuiIcon: {
            defaultProps: {
                baseClassName: "material-symbols-rounded",
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                variant: "contained",
            },
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: dp(20),
                    paddingLeft: dp(24),
                    paddingRight: dp(24),
                    height: dp(40),
                    fontSize: dp(14),
                    lineHeight: dp(20),
                    fontWeight: 500,
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                zIndex: -1000,
            },
        },
    },
});

export default theme;
