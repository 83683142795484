import React from 'react';
import Autocomplete from '../../../components/input/Autocomplete';
import OutlinedField from '../../../components/input/OutlinedField';
import OutlinedFileField from '../../../components/input/OutlinedFileField';
import PageDialog from '../../../components/modal/PageDialog';
import useProduct from './utils/useProduct';

export default function ProductForm() {
	const {
		name,
		setName,
		description,
		setDescription,
		price,
		setPrice,
		images,
		setImages,
		categories,
		setCategories,
		isLoading,
		isEdit,
		handleSubmit,
		categoriesOptions,
		isNewCategoryLoading,
		handleAddCategory,
	} = useProduct();

	return (
		<PageDialog
			title={isEdit ? 'Editar producto' : 'Nuevo producto'}
			titleIcon={isEdit ? 'edit_document' : 'note_add'}
			onDone={handleSubmit}
			loading={isLoading}
		>
			<OutlinedField
				label='Nombre'
				icon='label'
				value={name}
				onChange={setName}
			/>
			<OutlinedField
				label='Descripción'
				multiline
				rows={4}
				value={description}
				onChange={setDescription}
				disableClear
			/>
			<OutlinedField
				label='Precio'
				icon='attach_money'
				value={price}
				onChange={setPrice}
				type='number'
			/>
			<OutlinedFileField
				label='Imagenes'
				placeholder='Selecciona las imagenes'
				icon='photo_library'
				value={images}
				onChange={setImages}
				multiple
			/>
			<Autocomplete
				options={categoriesOptions || []}
				value={categories}
				onChange={setCategories}
				label='Categorías'
				placeholder='Categoría'
				onAddOption={handleAddCategory}
				loading={isNewCategoryLoading}
			/>
		</PageDialog>
	);
}
